import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { ButtonModule } from 'primeng/button';

import { ButtonComponent } from '../button/button.component';

@Component({
	selector: 'sl-text-button',
	templateUrl: './text-button.component.html',
	styleUrl: './text-button.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [ButtonModule, MatIconModule]
})
export class TextButtonComponent extends ButtonComponent {}
